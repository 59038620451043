// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-therapie-js": () => import("/opt/build/repo/src/pages/coaching-therapie.js" /* webpackChunkName: "component---src-pages-coaching-therapie-js" */),
  "component---src-pages-healing-js": () => import("/opt/build/repo/src/pages/healing.js" /* webpackChunkName: "component---src-pages-healing-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-living-js": () => import("/opt/build/repo/src/pages/living.js" /* webpackChunkName: "component---src-pages-living-js" */),
  "component---src-pages-living-een-jaar-voor-jezelf-js": () => import("/opt/build/repo/src/pages/living/een-jaar-voor-jezelf.js" /* webpackChunkName: "component---src-pages-living-een-jaar-voor-jezelf-js" */),
  "component---src-pages-living-engelen-cirkel-js": () => import("/opt/build/repo/src/pages/living/engelen-cirkel.js" /* webpackChunkName: "component---src-pages-living-engelen-cirkel-js" */),
  "component---src-pages-living-the-angel-experience-js": () => import("/opt/build/repo/src/pages/living/the-angel-experience.js" /* webpackChunkName: "component---src-pages-living-the-angel-experience-js" */),
  "component---src-pages-maak-een-afspraak-js": () => import("/opt/build/repo/src/pages/maak-een-afspraak.js" /* webpackChunkName: "component---src-pages-maak-een-afspraak-js" */),
  "component---src-pages-massages-js": () => import("/opt/build/repo/src/pages/massages.js" /* webpackChunkName: "component---src-pages-massages-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

